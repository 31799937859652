import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import ECommerceThrivingImgSource from 'images/blog-images/e-commerce-continuously-thriving.png';
import ImpactOfCoronoVirusChart from 'images/blog-images/impact-of-coronavirus-chart.png';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query eCommerceBlogImg {
      file(relativePath: { eq: "e-commerce-continuously-thriving.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="e-commerce continuously thriving during pandemic"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="E-Commerce Continuously Thriving During Pandemic | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-e-commerce" })}
        htmlAttributes={{
          lang: 'en',
        }}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "E-Commerce continuously Thriving During Pandemic | Shipkoo",
              content: intl.formatMessage({ id: "blog-post-meta-title-e-commerce" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: ECommerceThrivingImgSource,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="e-commerce-continuously-thriving-during-pandemic"
        title="E-Commerce Continuously Thriving During Pandemic"
        date="2020-05-21"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              The COVID-19 outbreak has dramatically affected businesses, the e-commerce industry, and economies around the globe. The big hits that the Coronavirus outbreak gave to the stock markets, businesses, and the economic growth of various countries show that specific government actions like lockdowns might not be enough to stop the economic decline. But on the other hand, the COVID-19 outbreak and the lockdown orders have been hugely beneficial for the e-commerce industry across the world.
            </p>

            <p>
              Online grocery shopping, entertainment shopping, and of course, apparel shopping, all have become increasingly popular. People follow "social distancing", rather than going to malls, and grocery shopping is mostly done online. Consumers are now more motivated to stay at home and shop online with the help of a wide variety of online marketplaces. Moreover, E-commerce has contributed to preserving jobs during this challenging time, along with making online services a lifeline.
            </p>

            <Image src={ECommerceThrivingImgSource} alt="dropshipping image"/>

            <p>
              Mobile devices are the most popular for online shopping, as a study shows that 72% of consumers are using their mobiles to shop online. An advantage for customers of this increased online shopping is that eCommerce players have been forced to improve the customer services online due to the intensity and energy COVID-19 has given.
            </p>

            <p>
              According to a survey of Italy's e-commerce industry after the Coronavirus outbreak, the e-commerce revenue has spiked to 81%. Similarly, China, the US, Iran, and several European Countries have noticed the increase in e-commerce revenue. Moreover, online education and consultation are creating new economic opportunities for many people across the globe.
            </p>

            <Image src={ImpactOfCoronoVirusChart} className="no-top shadow-on" alt="dropshipping boxes"/>

            <p className="small">
              Impact of coronavirus (COVID-19) on online sales in Italy between February and March 2020<br />
              Source: <a href="https://www.statista.com/statistics/1101844/impact-of-coronavirus-covid-19-on-e-commerce-in-italy/" target="_blank" rel="noreferrer">https://www.statista.com/statistics/1101844/impact-of-coronavirus-covid-19-on-e-commerce-in-italy/</a>
            </p>

            <p>
              Who knew that COVID-19 would forever change retailing and that it would have such an impact on the e-commerce industry? In time, the stay-at-home regulations will eventually disappear again. However, the change in behavior and attitude of millions of consumers towards online shopping will remain alive for many more years to come.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="LinkedinDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;